<template>
  <div class="account-config">
    <PageTitle
      :title="displayText.pageTitle"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.go(-1)"
    />
    <AccountInfo @refresh="refresh" />

    <SystemLineNotifySettings v-if="showControl.systemLineNotifySettings" @refresh="refresh" />

    <section class="section-block">
      <SectionTitle :title="displayText.block.changePassword.title" hideBtn />
      <BaseElForm label-position="left" label-width="250px" style="margin-top: 22px">
        <BaseElFormItem :label="displayText.block.changePassword.label.newPassword">
          <BaseElInput
            v-model="password"
            class="!w-full"
            style="margin-bottom: 10px"
            :placeholder="displayText.block.changePassword.placeholder.newPassword"
            show-password
          />
        </BaseElFormItem>
        <BaseElFormItem>
          <BaseElButton type="primary" class="ml-auto d-block" @click="updateUserPassword">
            {{ displayText.btn.confirmUpdate }}
          </BaseElButton>
        </BaseElFormItem>
      </BaseElForm>
      <!-- <BaseElForm label-position="left">
        <div class="form-container">
          <BaseElFormItem :label="formLineNotifyConfig.formlabel" class="mb-0">
            <BaseElButton
              :type="formLineNotifyConfig.btnType"
              class="ml-auto d-block"
              @click="lineNotify"
            >
              {{ formLineNotifyConfig.btnlabel }}
            </BaseElButton>
          </BaseElFormItem>
        </div>
      </BaseElForm> -->
    </section>
  </div>
</template>
<script>
import { ref, computed, onMounted, defineComponent, reactive, provide } from 'vue'
import {
  UpdateMePassword,
  LineNotify,
  FindMeLineNotify,
  UserNotificationGetMeBindings,
} from '@/api/user.js'
import adminRole from '@/config/admin'
import store from '@/store'
import { get } from 'lodash'
import SystemLineNotifySettings from './components/SystemLineNotifySettings.vue'
import { useOrg } from '@/use/useOrg'
import AccountInfo from './components/BasicInfo.vue'
import { i18n } from '@/plugins/i18n/i18n'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'AccountConfig',
  components: {
    AccountInfo,
    SystemLineNotifySettings,
  },
  props: {
    show: Boolean,
    width: String,
  },
  setup (props, { emit }) {
    const { dashboardConfig, orgId } = useOrg()
    const { checkActionByUserFeatures } = usePermissions()
    const password = ref('')
    const lineNotifyUsed = ref(false)
    const bindingConfigData = ref({})
    const adminRoleList = ['orgAdmin', 'shopManager', 'shopProvider', 'shopMod', 'ohbotAdmin']

    const customRoleOptions = ref([])
    const formData = reactive({
      emailNotify: true,
      notifyEmail: '',
      sameAsAccountEmail: false,
    })

    const displayText = computed(() => {
      const text = {
        pageTitle: i18n.t('accountInfo.page.title'),

        block: {
          changePassword: {
            title: i18n.t('accountInfo.block.changePassword.title'),
            label: {
              newPassword: i18n.t('accountInfo.changePassword.newPassword.label'),
            },
            placeholder: {
              newPassword: i18n.t('accountInfo.changePassword.newPassword.input.placeholder'),
            },
          },
        },
        btn: {
          confirmUpdate: i18n.t('common.button.confirmUpdate.text'),
        },
      }
      return text
    })

    const showControl = computed(() => {
      const controls = {
        systemLineNotifySettings: false,
      }

      if (
        get(dashboardConfig.value, 'orgModulePermission.enableUserNotificationLineBindingBeauty') ||
        get(
          dashboardConfig.value,
          'orgModulePermission.enableUserNotificationLineBindingWishmobile',
        )
      ) {
        if (checkActionByUserFeatures('admin.userNotification.updateLineBinding')) {
          controls.systemLineNotifySettings = true
        }
      }

      return controls
    })

    const formLineNotifyConfig = computed(() => {
      return lineNotifyUsed.value.use
        ? {
          formlabel: '重新綁定 LineNotify',
          btnlabel: '重新綁定',
          btnType: 'warning',
        }
        : {
          formlabel: '綁定 LineNotify',
          btnlabel: '前往綁定',
          btnType: 'primary',
        }
    })

    const availableRoleList = computed(() => {
      const availableRoleList = []
      adminRoleList.forEach((item) => {
        availableRoleList.push(adminRole[item])
      })
      customRoleOptions.value.map((item) => {
        availableRoleList.push({ name: item.name, value: item.role })
      })

      return availableRoleList
    })

    const updateUserPassword = async () => {
      if (password.value === '') {
        store.dispatch('message/warning', '請輸入新密碼')
        return
      }
      const [, err] = await UpdateMePassword({
        password: password.value,
      })
      if (err) {
        store.dispatch('message/error', err)
        return
      }
      password.value = ''
      store.dispatch('message/success', '密碼更新成功!')
    }

    const lineNotify = async () => {
      try {
        const res = await LineNotify({
          redirectURL: window.location.href,
        })

        window.location = res.oauthURL
      } catch (error) {
        store.dispatch('message/error', error || error.message)
      }
    }

    const findMeLineNotify = async () => {
      try {
        lineNotifyUsed.value = await FindMeLineNotify()
      } catch (error) {
        store.dispatch('message/error', error || error.message)
      }
    }

    const refresh = async () => {
      const callList = []
      callList.push(findMeLineNotify)
      callList.push(async () => {
        const [res, errMsg, rawErr] = await UserNotificationGetMeBindings({
          orgId: orgId.value,
        })
        if (rawErr) {
          window.$message.error(errMsg)
          return
        }
        bindingConfigData.value = res
      })

      await Promise.all(callList.map((fn) => fn()))
    }

    provide('refresh', refresh)
    provide('bindingConfigData', bindingConfigData)

    onMounted(async () => {
      refresh()
    })

    return {
      displayText,
      refresh,
      password,
      lineNotifyUsed,
      customRoleOptions,
      formLineNotifyConfig,
      availableRoleList,
      updateUserPassword,
      lineNotify,
      findMeLineNotify,
      formData,
      dashboardConfig,
      showControl,
      bindingConfigData,
    }
  },
})
</script>

<style scoped lang="postcss">
.account-config {
  .form-container {
    @apply w-full pt-[22px];
    background-color: white;
    border-radius: 4px;

    span {
      @apply text-gray-80;
      font-weight: 400;
    }
  }
}

.form-title {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
</style>
